<template>
  <div class="container">
    <div class="row">
      <div>
        <CategoriesList />
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesList from './components/CategoriesList.vue'

export default {
  name: 'Category',
  components: { CategoriesList },
};
</script>
