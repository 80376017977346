<template>
    <Button
        type="button"
        icon="pi pi-plus"
        label="Add"
        class="p-button-outlined"
        @click="openDialog()"
    />
    <Dialog 
        v-model:visible="showDialog"
        :style="{width:'90%'}"
        header="Create Category"
        :modal="true"
        class="p-fluid"
        :maximizable="true"
        @hide="closeDialog"
    >
        <div class="field">
            <label for="name">Name</label>
            <InputText
                id="name"
                v-model.trim="form.name"
                @blur="v$.form.name.$touch"
                required="true"
                autofocus
                :class="{'p-invalid': !form.name}"
            />
            <small class="p-error" v-if="!form.name">Name is required.</small>
        </div>
        <template #footer>
            <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog"
            />
            <Button 
                label="Save"
                icon="pi pi-check"
                class="p-button-text"
                :loading="createIsLoading"
                :disabled="this.v$.form.$invalid || createIsLoading"
                @click="create"
            />
        </template>
    </Dialog>

</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import useVuelidate from "@vuelidate/core";

import { required, maxLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
const toast = useToast()
export default {
    name: 'CreateCategory',
    components: {
        Button,
        Dialog,
        InputText,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading:true,
            createIsLoading: false,
            showDialog:false,
            form: {
                name:null,
            }
        }
    },
    validations() {
        return {
            form: {
                name: { required, maxLength:maxLength(64)},
            },
        }
    },
    methods: {
        openDialog(){
            this.showDialog = true;
        },
        closeDialog(){
            this.showDialog =false;
            this.form ={
                name: null,
            };
        },
        async create() {
            try {
                if (!this.v$.form.$invalid) {
                    this.createIsLoading= true;
                    await this.$store.dispatch('addNewCategory', this.form);
                    this.createIsLoading= false;
                    this.closeDialog();
                } else {
                    toast.error('Wrong category data ');
                }
            } catch (error) {
                this.createIsLoading =false;
                toast.error(error.message);
            }
        }
    },
}
</script>

