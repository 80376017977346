<template>
    <div class="card">
        <DataTable
            :value="categories"
            :loading="loading"
            responsive-layout="scroll"
            showGridlines
            :paginator="true"
            :rows="10"            
            :rowsPerPageOptions="[10,20,50,]"
            :rowHover="true"
            removableSort
            dataKey="id"
            @row-click="open"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            v-model:filters="filters"
            filterDisplay="menu"
            >
        <template #header>
            <h5 class="mb-2 md:m-0 p-as-md-center">Categories</h5>
            <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                    <CreateCategory />
                </div>

                <div class="col-sm-12 col-md-8">
                    <div class="d-flex flex-row justify-content-md-end">
                        <Button
                            type="button"
                            icon="pi pi-filter-slash"
                            label="Clear"
                            class="p-button-outlined me-4"
                            @click="clearFilter()"
                        />
                        <span class="p-input-icon-left" style="position: relative;">
                            <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                            <InputText
                                v-model="filters['global'].value"
                                placeholder="Keyword Search"
                                class="keywordSearch"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <Column
            v-for="col of columns"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            :sortable="true"
        >
        </Column>

        <Column :exportable="false" style="width: 10rem">
            <template #body="slotProps">
                <div style="display: grid; place-items: center;">
                    <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        @click="openDeleteDialog(slotProps.data)"
                    />
                </div>
                
            </template>
        </Column>

        <template #paginatorstart>
            <Button
                type="button"
                icon="pi pi-refresh"
                class="p-button-text" 
            />
        </template>
        </DataTable>
    </div>

    <EditCategory 
        :selectedElement= "selectedEditRow"
        @closeDialog="closeDialog($event)" 
    />
    <DeleteCategory 
        :selectedElement= "selectedDeleteRow"
        @closeDialog="closeDialog($event)" 
    />
    
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { FilterMatchMode , FilterOperator } from "primevue/api";
import { useToast } from "vue-toastification";
import CreateCategory from "./CreateCategory.vue"
import DeleteCategory from "./DeleteCategory.vue"
import EditCategory from "./EditCategory.vue"
const toast = useToast();

export default {
    components: {
        DataTable,
        Button,
        InputText,
        Column,
        CreateCategory,
        DeleteCategory,
        EditCategory,
    },
    data(){
        return {
            columns:null, 
            loading: false,
            filters:null,
            selectedEditRow: null,
            selectedDeleteRow:null,
        }
    },
    created(){
        this.columns = [
            {field: 'name' , header: 'Name'}
        ];
        this.initFilters();
    },
    async mounted(){
        await this.getCategories();
    },
    computed: {
        categories(){
            return this.$store.getters.obtainCategories;
        },
    },
    methods: {
        async getCategories(){
            try{
                this.loading= true;
                await this.$store.dispatch('obtainCategories' );
                this.loading = false;
            }
            catch(error){
                console.error('error')
                toast.error(error.message);
                this.loading=false;
            }
        },
        initFilters() {
            this.filters = {
                global: {value : null , matchMode: FilterMatchMode.CONTAINS},
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        {value: null, matchMode:  FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter(){
            this.initFilters();
        },
        open(event){
            this.selectedEditRow = event.data;
        },
        openDeleteDialog(element){
            this.selectedDeleteRow = element; 
        },
        closeDialog(){
            this.selectedEditRow= null;
            this.selectedDeleteRow = null; 
        }
    },
}
</script>
<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>
